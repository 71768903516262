import classNames from 'classnames';
import Immutable from 'immutable';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { ClickOutside } from '@alkem/react-ui-click-outside';

import { SOURCE_PRODUCTDNA } from 'constants/organization-source';
import { FRANCE } from 'constants/targetMarkets';
import {
  getOrganizationSource,
  getOrganizationTargetMarkets,
  isManufacturer,
} from 'core/api/user';
import { selectUser } from 'modules/user';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import './help-center.scss';

const HelpCenterDropdown = () => {
  return (
    <div className="dropdown-menu dropdown-menu-right" role="menu">
      <div>
        <a
          className="dropdown-item"
          href="https://help.salsify.com/SupplierXM/s"
          target="_blank"
          rel="noreferrer"
        >
          <i className="mdi mdi-school" />{' '}
          {i18n.t('frontproductstream.navbar_help.academy.link', {
            defaultValue: 'SupplierXM Portal',
          })}
        </a>
      </div>
    </div>
  );
};

export const HelpCenter = () => {
  const user: UserImmutable = useSelector(selectUser);
  const [helpCenterOpen, setHelpCenterOpen] = useState(false);
  const mainRef = useRef(null);

  const isProductDNA = getOrganizationSource(user) === SOURCE_PRODUCTDNA;
  const targetMarkets: Immutable.List<Immutable.Map<string, any>> =
    getOrganizationTargetMarkets(user) || Immutable.List();
  const isFrenchTM = targetMarkets.some((tm) => get(tm, ['id']) === FRANCE);

  if (isManufacturer(user) && !isProductDNA && isFrenchTM) {
    return (
      <li
        ref={mainRef}
        id="academy-access"
        data-testid="helpCenter"
        className={classNames('dropdown Navbar__dropdown HelpCenter', {
          open,
        })}
      >
        <button
          className="dropdown-toggle HelpCenter__button"
          data-testid="HelpCenterButton"
          onClick={() => setHelpCenterOpen((isOpen) => !isOpen)}
        >
          <i className="mdi mdi-help-circle HelpCenter__helpIcon " />
          <i className="mdi mdi-chevron-down" />
        </button>
        {helpCenterOpen && (
          <ClickOutside
            ref={mainRef}
            onClickOutside={() => setHelpCenterOpen(false)}
          >
            <HelpCenterDropdown />
          </ClickOutside>
        )}
      </li>
    );
  }
  return (
    <li className="nav-item HelpCenter" data-testid="helpCenter">
      <button className="HelpCenter__button" data-testid="HelpCenterButton">
        <i
          className="mdi mdi-help-circle HelpCenter__helpIcon"
          data-elevio-article="index"
        />
      </button>
    </li>
  );
};

export default HelpCenter;
